body {
  background-color: #eaeaea;
}

.ui.fixed.menu {
  background-image: linear-gradient(
    135deg,
    rgb(224, 15, 15) 0%,
    rgb(224, 15, 15) 69%,
    rgb(224, 15, 15) 89%
  );
}

.ui.main.container {
  margin-top: 7em;
}

.masthead {
  display: flex;
  align-items: center;
  background-image: linear-gradient(
    135deg,
    rgb(224, 15, 15) 0%,
    rgb(224, 15, 15) 69%,
    rgb(224, 15, 15) 89%
  ) !important;
  height: 100vh;
}

.masthead h1.ui.header {
  font-size: 4em;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-calendar {
  width: 100%;
  border: none;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, .15);
}